/* ---------------------------------------- PROPERTY HIERARCHY ---------------------------------------- */

/* 
    Container: outside to inside (position, parental behavior, margin, border, size, padding, background)
    Non-Container Children: alphabetical
    Interactives: cursor, alphabetical 
*/

/* ---------------------------------------- ELEMENTS ---------------------------------------- */

body {
	display: flex;
	flex-direction: column;
	font-family: 'Questrial Regular', sans-serif;
}

img {
	width: 100%;
}

h2 {
	font-size: 1.3rem;
	margin: 30px 0;
}

p, a {
	color: var(--black);
	font-size: 0.8rem;
	letter-spacing: 2px;
	line-height: 1.3rem;
	text-decoration: none;
	text-transform: uppercase;
}

/* ---------------------------------------- CLASSES ---------------------------------------- */

.links-container {
	display: flex;
	justify-content: space-between;
	padding: 1rem 3vh;
	width: 100%;
	text-align: right;
}

.links-container span {
	font-size: 1.5em;
}

.skillset-container {
	padding: 0 3vh;
	background: var(--black);
	color: white;
}

.initializations > p {
	color: white;
	text-transform: none;
}

.skillset-item {
	padding-left: 50px;
}

.skillset-item p {
	color: white;
}

.skillset-header {
	font-weight: bold;
}

.projects-container {
	padding-left: 3vh;
	background: var(--black);
	color: white;
}

.project-item {
	padding-left: 50px;
	color: white;
	font-size: 0.8rem;
	font-weight: bold;
	line-height: 1.5rem;
}

.projects-subtext {
	margin-bottom: 30px;
	padding-right: 3vh;
	color: white;
}