@font-face {
  src: url('./fonts/ArialBlack/Arial-Black.eot');
  src: url('./fonts/ArialBlack/Arial-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ArialBlack/Arial-Black.woff2') format('woff2'),
    url('./fonts/ArialBlack/Arial-Black.woff') format('woff'),
    url('./fonts/ArialBlack/Arial-Black.ttf') format('truetype'),
    url('./fonts/ArialBlack/Arial-Black.svg#Arial-Black') format('svg');
  font-family: Arial-Black;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  src: url('./fonts/Questrial/Questrial-Regular.eot');
  src: url('./fonts/Questrial/Questrial-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Questrial/Questrial-Regular.woff2') format('woff2'),
    url('./fonts/Questrial/Questrial-Regular.woff') format('woff'),
    url('./fonts/Questrial/Questrial-Regular.ttf') format('truetype'),
    url('./fonts/Questrial/Questrial-Regular.svg#Questrial-Regular') format('svg');
  font-family: Questrial;
  font-style: normal;
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
  color: var(--black);
}

:root {
  --black: #222222;
  --accent: #BDD0E0;
}

::-webkit-scrollbar {
  display: none;
}
::-moz-scrollbar {
  display: none;
}

::selection {
  background: var(--black);
  color: white;
}
::-moz-selection {
  background: var(--black);
  color: white;
}